.collapsible-side-panel {
  height: 100%;
  position: fixed;
  width: 400px;
  top: 0px;
  left: 0px;
  cursor: default;
}

.num-per-page-selection {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 6px;
}

.table-row-rectangle {
  width: 5px;
  min-height: 55px;
  height: 100% !important;
  display: flex;
  flex-flow: column;
}

.table-row-success-rectangle {
  background-color: #00d97e;
}

.table-row-failure-rectangle {
  background-color: #ff2a48;
}

.table-row-unknown-rectangle {
  background-color: #e2e6ed;
}

.low-padding-table td,
.low-padding-table th {
  padding: 0.5rem !important;
}

.table-pagination-footer {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.cursor-default {
  cursor: default !important;
}

.badge-min-width {
  min-width: 60px;
}

.comment-pill {
  top: 0 !important;
}

.divider {
  border-top: 1px solid #edf2f9;
}

.text-align-start {
  text-align: start;
}

.opaque-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #12263f;
}
.opaque-backdrop.fade {
  opacity: 0;
  pointer-events: none;
}
.opaque-backdrop.show {
  opacity: 0.5;
}

.log-side-panel {
  background: white;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1050;
  overflow-y: auto;
  box-shadow: -1px 0px 0px #edf2f9;
}
