.search-input {
  border-bottom: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: rgb(187, 187, 187);
  border-color: _palette(blue);
  background-color: _palette(blue);
}

.custom-file-upload {
  width: 132px;
  height: 32px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  /* Border Color */

  border: 1px solid #eaeef3;
  border-radius: 6px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px 6px 8px;
  font-family: "Cerebri Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  letter-spacing: -0.01em;

  color: #8492a6;
}

.form-control-is-editing {
  background-color: #f2f7ff;
  border-color: #1a57f6;
}

.dropdown-menu-options {
  padding: 12px;
  margin-top: -6px;
  margin-bottom: -6px;
  margin-left: -24px;
  margin-right: -24px;
}

.dropdown-menu-options:hover {
  background: #f4f8ff !important;
  color: #003fff !important;
}
