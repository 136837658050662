.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.h1,
h1 {
  font-size: 40px;
  line-height: 56px;
}

.h2,
h2 {
  font-size: 32px;
  line-height: 44px;
}
.h3,
h3 {
  font-size: 24px;
  line-height: 36px;
}
.h4,
h4 {
  font-size: 18px;
  line-height: 28px;
}
.h5,
h5 {
  font-size: 16px;
  line-height: 26px;
}
.h6,
h6 {
  font-size: 14px;
  line-height: 24px;
}

a {
  color: _palette(blue);
}

a:hover {
  color: _palette(blue-dark);
}

a.black {
  color: _palette(black);
}

a.black:hover {
  color: _palette(black-light);
}

.text-primary {
  color: _palette(blue) !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: _palette(blue-dark) !important;
}

.btn-link {
  font-weight: 400;
  color: _palette(blue);
  text-decoration: none;
}

.btn-link:hover {
  color: _palette(blue-dark);
  text-decoration: none;
}

code {
  color: _palette(blue);

  :not(pre) > & {
    padding: 3px 4px;
    border-radius: 5px;
    background: _palette(code-bg);
    border: 1px solid _palette(code-border);
  }
}

.black {
  color: _palette(black) !important;
}

.blue-text {
  color: _palette(blue) !important;
}

.red {
  color: _palette(red) !important;
}

.yellow {
  color: _palette(yellow) !important;
}

.gray {
  color: _palette(gray) !important;
}

.green {
  color: _palette(green) !important;
}

.purple {
  color: _palette(purple) !important;
}

.white {
  color: _palette(white) !important;
}

.paragraph-m-0 p {
  margin: 0px !important;
}

.empty-state-text {
  color: _palette(empty-state);
}
.copy-state-text {
  color: _palette(copy-state);
}

.markdown {
  h1 {
    font-weight: 600;
    margin-bottom: 1.75rem;
  }
}

.chevron-offset-text-align {
  padding-top: 2px;
  margin-left: 2px;
}

.medium {
  font-weight: 500;
}

.font-size-50 {
  font-size: 50% !important;
}

.semibold {
  font-weight: 600;
}
