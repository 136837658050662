/* Typography */

.small-text {
  font-size: 0.8125rem;
}

/* End Typography */

/* Shared */

.separator {
  background-color: #e4ebf6;
  height: 1px;
  padding-left: 5%;
  padding-right: 5%;
}

.modal-body {
  max-height: 700px;
  overflow-y: scroll;
}

button:disabled {
  cursor: default;
  pointer-events: all !important;
}

/* End Shared */

/* JSON Schema Diagram */

.json-schema--vertical-line {
  flex: 0 0 1px !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
  border-right: 1px solid #d2ddec;
}

.var-label {
  font-size: 0.85em;
  text-decoration: none;
  color: black;
}

/* End JSON Schema Diagram */

/* Blueprint Canvas */

.blueprint-editor-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.blueprint-canvas {
  transition: transform 200ms ease-out;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
  padding-top: 24px;
  position: relative;
}

.add-step-button {
  background-color: #e3ebf6;
  border: 2px solid #95aac9;
  box-sizing: border-box;
  border-radius: 3px;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #95aac9;
}

.add-step-button:hover {
  background-color: #95aac9;
  color: #e3ebf6;
}

.modal-background-blur {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: gray;
  opacity: 0.4;
}

/* End Blueprint Canvas */

/* Blueprint Cards */

.step-card {
  width: 350px !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 100px #0099ff;
  padding: 4px;
}

.arrow-button {
  width: 68px;
  height: 22px;
  border-radius: 5px;
  border: 1px solid #c9d0da;
  background-color: #fbfdff;
  color: #c9d0da;
  line-height: 1 !important;
}

.pathkey-button {
  width: 68px;
  height: 22px;
  border-radius: 5px;
  border: 1px solid #c9d0da;
  background-color: #fbfdff;
  color: #c9d0da;
  line-height: 0 !important;
  text-transform: uppercase;
  z-index: 1;
}

.left-arrow {
  transform: scaleX(-1);
}

.arrow-padding {
  padding-bottom: 3px;
}

.ghost-step {
  background-color: #e3ebf6 !important;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.step-card-not-highlighted {
  box-shadow: none !important;
}

.step-card-highlighted {
  box-shadow: 0px 0px 0px 2px #003fff !important;
}

.step-card-descendant-of-selected-step {
  box-shadow: 0px 0px 0px 2px #94b0ff !important;
  background-color: #f2f5ff !important;
}

.step-card-error {
  box-shadow: 0px 0px 0px 2px #ff2a48 !important;
}

.step-card-warning {
  box-shadow: 0px 0px 0px 2px #ffaf19 !important;
}

.step-card-log-icon-tooltip [class$="inner"] {
  background-color: #12263f;
  color: #ffffff;
  max-width: 100% !important;
}

.step-card-log-tooltip-content {
  padding: 4;
  margin: 4;
}

.step-card-log-tooltip-content > ul {
  list-style-position: inside;
  padding-left: 0;
  margin: 0;
}

.card-image {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #edf2f9;
}

.trigger-card-image {
  background-color: #01c391;
}

.cursor-pointer {
  cursor: pointer !important;
}

.card-arrow-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  width: 100%;
  opacity: 0;
}

.card-arrow-box:hover {
  opacity: 1;
}

.card-arrow {
  left: 50%;
}

.card-arrow-bottom {
  transform: scaleY(-1);
}

.delete-blueprint-modal {
  overflow: hidden !important;
}

/* End Blueprint Cards */

/* Left Panel */

.left-panel {
  min-width: 350px;
  width: 350px;
  height: 100%;
  box-shadow: 1px 0px 0px #edf2f9;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-animation: slide 0.1s forwards;
  animation: slide 0.1s forwards;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
}

@-webkit-keyframes slide {
  100% {
    left: 0px;
  }
}

@keyframes slide {
  100% {
    left: 0px;
  }
}

.btn:focus,
button:focus,
.btn-outline-primary:focus,
.btn-outline-secondary:focus {
  outline: none !important;
  box-shadow: none;
}

.badge .close {
  margin-left: 0.25rem;
  color: inherit;
  font-size: 100%;
  text-shadow: 0 1px 0 rgba(#000, 0.5);
}

.event-type-rectangle {
  width: 5px;
  min-height: 40px;
  height: 100% !important;
  display: flex;
  flex-flow: column;
}

.console-error {
  background-color: #ff2a48;
}
.console-warning {
  background-color: #ffaf19;
}

.console-info {
  background-color: #e2e6ed;
}

.console-table-message {
  align-items: center;
  display: flex;
  gap: 8px;
}

/* End Left Panel */

/* Right Panel */

.commonModelsCollapse {
  color: #6e84a3;
  font-family: "Cerebri Sans", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
}

.commonModelsFields {
  width: 100%;
  table-layout: fixed;
}

/* End Right Panel */

.clickable-icon {
  cursor: pointer;
  color: #95aac9;
}

.clickable-icon:hover {
  opacity: 0.7;
}

.vertical-line {
  border-left: 1px solid #d2ddec;
}

.trigger-input-value {
  width: 60px !important;
}

.trigger-dropdown-unit {
  min-width: 0 !important;
  flex-grow: 1;
}

/* Diagnostics */

.diagnostic-item {
  position: relative;
  display: block;
  padding: 1rem 1.75rem;
  background-color: transparent;
  border: 1px solid #edf2f9;
}

.permission-modal-width {
  max-width: 500px !important;
}

.scraper-urls-modal-width {
  max-width: 750px !important;
}

.mark-unsupported-modal-width {
  max-width: 400px !important;
}
