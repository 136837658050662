.SignUp,
.Login,
.ForgotPassword,
.ResetPassword {
  background: radial-gradient(49.59% 55.76% at 67.95% 51.18%, #ffffff 0%, #efefef 100%);
  min-height: 100vh;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url("../../../assets/img/mark-perspective-slight-right-1.png");
    background-size: 75% auto;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.65;

    @media (min-width: 768px) {
      background-size: auto 90%;
      background-position: 110% -50%;
      opacity: 1;
    }
  }

  a {
    color: #080808;
  }

  &-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 24px 40px;
    height: 88px;
  }

  &-form {
    min-height: 100vh;

    @media (min-width: 768px) {
      padding-bottom: 8%;
    }
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    letter-spacing: -0.03em;

    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 52px;
    }
  }

  .form-group {
    max-width: 440px;
    margin-right: auto;
    margin-left: auto;
  }

  label:not(.form-check-label) {
    font-weight: 500;
  }

  input.form-control {
    background: #ffffff;
    border-radius: 6px;
    height: 48px;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 0px 1px rgba(103, 110, 144, 0.04), 0px 6px 12px rgba(54, 57, 62, 0.1);
    border: 0;

    &::placeholder {
      color: #c3c5c7;
    }
  }

  .btn-primary {
    background: #080808;
    box-shadow: 0px 0px 0px 1px #080808, 0px 8px 20px rgba(52, 56, 60, 0.36);
    border-radius: 6px;
    height: 48px;
    border: 0;
    font-weight: 500;
    margin: 32px 0 40px;
  }

  .btn-sign-up-instead,
  .btn-sign-in-instead {
    background: #ffffff;
    border: 1px solid #dce2e4;
    height: 40px;
    border-radius: 20px;
    padding: 0 1rem;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.15s ease-out;
    margin-left: 10px;

    &:hover {
      background: #f3f4f6;
    }
  }
}

.SignUp {
  &:before {
    background-position: center 25vh;

    @media (min-width: 768px) {
      background-position: 110% -50%;
    }
  }

  &-form {
    padding-top: 100px;

    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-group {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .selling-points {
    @media (min-width: 768px) {
      padding-left: 40px !important;
    }
    @media (min-width: 992px) {
      padding-left: 96px !important;
    }

    h3 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  .selling-point {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-left: 4px;
    margin-bottom: 24px;
    display: flex;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 28px;
      margin-left: 0;
    }

    &-check {
      margin: 2px 16px 0 0;
      min-width: 20px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: rgba(0, 63, 255, 0.09);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;
      color: #003fff;
      font-size: 16px;
    }
  }
}
