.left-nav-logo {
  padding: 12px 0 12px;
}

@media (max-width: 767.98px) {
  .left-nav-logo svg {
    transform: scale(1.1);
  }
}

.nav-item {
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
}

.navbar-vertical {
  overflow-y: visible !important;
}

@media (max-width: 767.98px) {
  .logo-divider {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical .logo-divider {
    margin: 0.75rem -24px 0 !important;
    border-top: 1px solid #e3ebf6;
  }

  .navbar-vertical > [class*="container"] {
    height: 100vh;
    justify-content: flex-start !important;
  }

  .navbar-vertical .navbar-collapse {
    overflow-y: auto;
    max-height: calc(100vh - 46.41px - 12px - 80px);
    padding: 16px 0;
  }

  .navbar-vertical .navbar-user.d-md-flex {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 80px;
    justify-content: start;
  }

  .navbar-vertical.navbar-expand-md .navbar-user {
    border-top-width: 0;
  }
}

.navbar-heading {
  margin: 20px 0 0;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: _palette(black);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: _palette(blue);
  background: _palette(blue-bg);
}

.navbar-nav .nav-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0 !important;
  bottom: 0 !important;
  border-left: 3px solid _palette(blue) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: _palette(black);
  background-color: transparent;
  border-color: transparent transparent _palette(black);
}

.dropup {
  flex: 1;
  height: 84%;
  display: flex;
  align-items: center;

  .dropdown-menu {
    width: 104%;
  }
}

.left-nav-dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.left-nav-dropdown-toggle .user-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.left-nav-dropdown-toggle .avatar {
  margin-right: 12px;
}

.left-nav-dropdown-toggle .user-preview-text .username {
  color: #6e84a3;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-nav-dropdown-toggle .user-preview-text .header-pretitle {
  margin-bottom: 0.3rem;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-nav-dropdown-toggle i {
  color: #6e84a3;
}

.navbar-nav .nav-link > .fe {
  padding-bottom: 0 !important;
}

.nav-link.disabled {
  opacity: 0.35;
  cursor: auto !important;
}

.sub-navbar-nav {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding-right: 0;
  padding-left: 0;
}

.sub-navbar-nav a {
  color: #7283a0 !important;
  border-left: 0px !important;
}

.sub-navbar-nav .sub-nav-link.active {
  color: #080808 !important;
  background-color: #eff1f5 !important;
  border-left: 0px !important;
  font-weight: 600 !important;
  border-radius: 6px;
}

.sub-navbar-nav .sub-nav-link.active:before {
  border-left: 0px !important;
}

.sub-navbar-nav-side {
  margin-left: -23px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding-right: 0;
  padding-left: 0;
  width: 64px;
}

.sub-navbar-nav-side a {
  border-left: 0px !important;
}

.sub-navbar-nav-side .sub-nav-link.active {
  background-color: rgba(244, 248, 255, 0.15) !important;
  border-left: 0px !important;
  font-weight: 600 !important;
}

.sub-navbar-nav-side .sub-nav-link.active:before {
  border-left: 0px !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: _palette(blue);
  background: _palette(bg-readonly);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: _palette(blue);
  background: _palette(blue-bg);
}

.sub-navbar-nav .sub-nav-link.active {
  color: _palette(blue) !important;
  background-color: _palette(blue-bg) !important;
  border-left: 0px !important;
  border-radius: 6px;
}
