.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 0px 5px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 0px 5px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #e63757;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.trial-hero {
  background-image: url("../../../assets/img/mark-quicksilver.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50%;
  margin: -24px;
}
