.badge-primary {
  color: #fff;
  background-color: _palette(blue);
}

.badge-soft-danger {
  color: _palette(red);
  background-color: _palette(red-bg);
}

.badge-soft-danger[href]:focus,
.badge-soft-danger[href]:hover {
  color: _palette(red);
  text-decoration: none;
  background-color: _palette(red-bg-hover);
}

.http-method-badge {
  padding: 2px 5px;
  border-radius: 4px;

  b {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    font-weight: 700;
  }
}

.badge-green {
  color: _spectrum(green50);
  background-color: _spectrum(green5);
}

.badge-purple {
  color: _spectrum(purple50);
  background-color: _spectrum(purple5);
}
