body {
  color: _palette(black);
  background: _palette(bg);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  // TODO: Restore these base fallback fonts after we add Cerebri to SCSS
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  //   "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  // TODO: Restore these base fallback fonts after we add Cerebri to SCSS
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
