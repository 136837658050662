button {
  padding-block: 0;
  padding-inline: 0;
}

.form-control[readOnly] {
  color: #6e84a3 !important;
  cursor: default;
  background-color: _palette(bg-readonly) !important;
}

.form-check-input {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #e3ebf6;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: transparent;
  color-adjust: exact;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;
  cursor: pointer;
}

.transition-margin {
  transition: margin 500ms;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.375rem;
  background-color: white;
  border: 1px solid #075ff7;
}

.form-check-input:checked {
  background-color: _palette(blue);
  border-color: _palette(blue);
}

.form-check:not(.form-switch):not(.form-check-circle) .form-check-input:checked {
  background-size: 80% 80%;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #e3ebf6;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: transparent;
  color-adjust: exact;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;
  cursor: pointer;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.375rem;
}

.form-check-input:checked {
  background-color: _palette(blue);
  border-color: _palette(blue);
}

.form-check:not(.form-switch):not(.form-check-circle) .form-check-input:checked {
  background-size: 80% 80%;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'  d='M2 11l4 4l11-10' /%3e%3c/svg%3e");
}

.form-control:focus {
  border-color: _palette(blue) !important;
}

.input-group-flush > .form-control {
  border: 0 !important;
}

.avatar-xxs {
  width: 16px;
  height: 16px;
  font-size: 0.54167rem;
}

.custom-select:focus {
  border-color: _palette(blue) !important;
}

.accordion-height {
  height: 100% !important;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  transform-origin: center;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@keyframes rotating-counter {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.rotating-counter {
  transform-origin: center;
  -webkit-animation: rotating-counter 2s linear infinite;
  -moz-animation: rotating-counter 2s linear infinite;
  -ms-animation: rotating-counter 2s linear infinite;
  -o-animation: rotating-counter 2s linear infinite;
  animation: rotating-counter 2s linear infinite;
}

.accordion-show:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
  transform: rotate(-180deg);
}

.accordion-collapse:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
}

.recommendation-show:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
  transform: rotate(-180deg);
}

.recommendation-collapse:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
}

.recommendation-copy-button {
  cursor: pointer;
}

.recommendation-instruction-list {
  list-style-position: outside;
  color: _palette(copy-state);
}

.recommendation-header {
  border-bottom: 1px solid _palette(black-light) !important;
}

.email-recommendation-subtitle {
  margin-bottom: 0.75rem !important;
}

.copy-instructions-container {
  background: #272828;
  border: 1px dashed #5c5e6a;
  border-radius: 6px;
}

.search {
  background-color: #edf2f9 !important;
  border: 0px;
}

.search-filters {
  color: #080808 !important;
}

.search-filters::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #080808;
  opacity: 1;
  /* Firefox */
}

.search-filters:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #080808;
}

.search-filters::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #080808;
}

.dropdown-menu {
  box-shadow: $card-box-shadow;
}

.dropdown-menu-card .card-body {
  overflow-y: visible !important;
}

.custom-dropdown-button[aria-expanded="true"] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.custom-dropdown-menu {
  min-width: 100% !important;
  font-size: 0.8125rem !important;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.custom-dropdown-option {
  font-weight: normal;
  display: block;
  line-height: 1.75 !important;
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
  padding-left: 0.5rem !important;
}

.context-menu {
  position: fixed;
  background-color: white;
  z-index: 999;
  padding: 0px;
  box-shadow: 0px 2px 6px rgba(39, 39, 39, 0.12), 0px 5px 10px rgba(39, 39, 39, 0.1),
    0px 8px 16px rgba(39, 39, 39, 0.08);
  border-radius: 8px;
}

.context-menu-item {
  cursor: pointer;
  padding: 12px;
  margin: 0px;

  &:hover {
    background-color: _palette(border-light);
  }
}

.table-link {
  height: 30px;
}

.table-link:hover {
  cursor: pointer;
}

.forced-feedback {
  display: block !important;
}

.ellipses-dropdown-item {
  font-size: 0.9375rem !important;
}

/* Merge code block*/
.code-block {
  max-height: 500px;
  border-radius: 10px;
  padding: 16px 20px;
}

.code-block-container {
  border-radius: 10px;
  background: rgb(1, 22, 39);
  color: #60819c;
}

.code-block-header {
  margin: 12px;
}

.code-block-language {
  margin: 12px;
  cursor: pointer;
}

.code-block-language-selected {
  color: #127cd2;
}

.clickable-card:hover {
  color: #6e84a3 !important;
  background-color: #f9fbfd !important;
  cursor: pointer;
}

.json-text-box {
  background-color: white;
  border: 1px solid #d2ddec;
  border-radius: 8px;
  height: 100px;
  overflow: auto;
}

/* Dashboard css */
.dashboard-number-header {
  font-size: 40px;
  color: _palette(black);
  font-weight: 600 !important;
}

.dashboard-button {
  border: 1px solid #e3ebf6 !important;
}

.dashboard-button:hover {
  color: #6e84a3 !important;
  background-color: #f9fbfd !important;
  border: 1px solid #e3ebf6 !important;
}

.alert-dark-gradient {
  color: #ffffff;
  background-image: linear-gradient(90deg, #080808, #3c3c3c);
}

/* Merge tooltip css */
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #12263f !important;
}

.tooltip-inner {
  color: #ffffff !important;
  background-color: #12263f !important;
}

.custom-tab-logo {
  margin-top: -2px;
}

/* Integrations dashboard */
.integration-dashboard-table-nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.integration-dashboard-table-nav-link:focus,
.integration-dashboard-table-nav-link:hover {
  text-decoration: none;
}

.integration-dashboard-table-nav-link.disabled {
  color: #95aac9;
  pointer-events: none;
  cursor: default;
}

/* Webhooks */
.webhook-common-model:hover {
  background-color: rgba(230, 55, 87, 0.03) !important;
  color: #e63757 !important;
}

.webhook-common-model:hover span {
  color: #e63757 !important;
}

.webhook-remove {
  color: #95aac9;
  cursor: pointer;
}

/* Modal css */
.blue-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #f9fbfd;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  outline: 0;
  padding: 30px;
}

.blue-modal-header {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e3ebf6;
}

.blue-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding-top: 1.5rem;
}

.blue-modal-card {
  height: 400px;
  overflow: auto;
}

.modal-shadow {
  box-shadow: 0px 4px 20px rgba(31, 45, 61, 0.35);
}

.modal-padding-large {
  padding: 52px !important;
}

.modal-narrow {
  max-width: 442px !important;
}

.modal-extra-narrow {
  max-width: 320px !important;
}

.modal-button {
  width: 136px;
  height: 48px;
}

/* Rechart css */
.recharts-cartesian-axis-tick {
  font-size: 0.8125rem;
  font-family: "Cerebri Sans", sans-serif;
  color: #95aac9;
}

.recharts-tooltip-popover {
  padding: 0.8rem 0.95rem;
  z-index: 1060;
  display: block;
  max-width: 10rem;
  font-family: "Cerebri Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(18, 38, 63, 0.2);
  border-radius: 0.5rem;
}

.container-shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.nav-item.active {
  background: linear-gradient(0deg, #3d63eb 5%, white 0%);
}

.separator {
  margin-left: 22%;
  margin-right: 22%;
  height: 1px;
  background-color: #eaeaea;
}

.docs--operation {
  border-bottom: 1px solid #e3ebf6;
}

.docs--operation:last-child {
  border-bottom: 0px !important;
}

.no-shadow {
  box-shadow: none !important;
}

.dashed-outline-rounded-container {
  background: _palette(bg-readonly);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23B2BECF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 14px;
  position: relative;
}

.dashed-outline-buttons-container {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: _palette(bg-readonly);
  vertical-align: center;
  border-radius: 6px;
  padding: 5px;
  margin: 2px;
}

.live-waiting--container {
  width: 16px;
  height: 16px;
}

%live-waiting {
  &.bg-red {
    background: _palette(red);
  }

  &.bg-green {
    background: _palette(green);
  }
}

.live-waiting--outer-circle {
  @extend %live-waiting;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  animation-name: ping-dissipate;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
}

.live-waiting--inner-circle {
  @extend %live-waiting;
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
}

/* Onboarding */
.onboarding--sticky-sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 30px;
}

.linking-flow-image-container {
  border-radius: 8px;
}

.linking-flow-image {
  width: 280px;
  box-shadow: 0px 3px 10px rgba(61, 63, 64, 0.3);
  border-radius: 10px;
}

@keyframes ping-dissipate {
  from {
    transform: scale(1);
    opacity: 0.5;
  }

  to {
    transform: scale(2.35);
    opacity: 0;
  }
}

/* API Request Tester Page*/

.base-api-url-prepend {
  display: contents;
}

.base-api-url-text {
  background-color: _palette(bg-readonly);
  overflow: scroll;
}

aside {
  padding: 24px;
  background: _palette(bg-img);
  border: 1px solid _palette(border);
  border-radius: 0.5rem;
}

/* Error boundary */

.error-boundary {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: _palette(gray);
  text-align: center;
}

.error-boundary-text {
  color: _palette(gray) !important;
}

.solid-outline-rounded-container {
  background: #eaeef3 !important;
  /* Gray / Gray 30 */
}
