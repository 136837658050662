.card {
  border-color: #e3ebf6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.clickable {
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0s, box-shadow 0s;

    hr {
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:hover {
      background-color: #f9fbfd;
      border-color: #d2ddec;

      hr {
        border-color: #d2ddec !important;
      }
    }
  }
}
