/**
 * 400, 500, and 600 are handled by theme.min.css
 */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../css/fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("../../css/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../css/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("../../css/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../css/fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
    url("../../css/fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../css/fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("../../css/fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Cerebri Sans";
  src: url(../../css/fonts/cerebrisans/cerebrisans-bold.eot);
  src: url(../../css/fonts/cerebrisans/cerebrisans-bold.eot?#iefix) format("embedded-opentype"),
    url(../../css/fonts/cerebrisans/cerebrisans-bold.woff) format("woff"),
    url(../../css/fonts/cerebrisans/cerebrisans-bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
