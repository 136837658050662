.integrations-list {
  margin-bottom: auto;
  .fe-chevron-down {
    width: 20px;
    height: 20px;
  }
  &-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
    }
  }
}
.grayout {
  opacity: 0.3; /* Real browsers */
  filter: alpha(opacity = 30); /* MSIE */
}
.active-sorted-row {
  color: #95aac9 !important;
  cursor: row-resize !important;
}
.sortable-integration-handle {
  color: #95aac9 !important;
  cursor: row-resize;
  width: max-content;
  height: max-content;
}
.sortable-integration-handle:hover {
  opacity: 0.7;
}
.sortable-integration-handle:active {
  cursor: row-resize;
}
