.modal.fit-content .modal-dialog {
  width: fit-content;
}
.date-picker-body {
  overflow-y: visible;
}
.date-range-dash {
  font-weight: 400;
  font-style: normal;
  font-style: 15;
  line-height: 24;
  color: black;
}
.date-picker {
  display: flex;
  color: black;
  font-size: 15px;
}
.date-picker-day {
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  color: black;
}
.date-picker-dash {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: #080808;
}
.day-disabled {
  color: #ccc;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f7f8f9;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__day--selected {
  background-color: _palette(blue);
}
.react-datepicker__day--keyboard-selected {
  background-color: _palette(blue);
}
.react-datepicker__day--selected:hover {
  background-color: _palette(blue);
}
.react-datepicker__day--in-selecting-range {
  background-color: _palette(blue);
}
.react-datepicker__day--in-selecting-range:hover {
  background-color: _palette(blue);
}
.react-datepicker__current-month {
  margin-top: 0;
  color: #000;
  font-weight: 700;
  font-size: 13px;
}
