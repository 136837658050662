/*
* this is a set of base layers which mimics tailwind's "preflight" functionality. They exist in similar form in `merge-frontend`. 
* At some point, we'll want to activate preflight with Tailwind, but to do that will require a large refactor as it completely distorts some portions of our frontend
*/

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-color: inherit;
  background: inherit;
  background-color: inherit;
  color: inherit;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: inherit;
}

::before,
::after {
  --tw-content: "";
}

button,
[role="button"] {
  cursor: pointer;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
